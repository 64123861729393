import React from 'react'
import { navigate } from 'gatsby'
import { Head } from '@components'
import * as st from '@assets/styl/404.module.styl'
import { useLanguage } from '../hooks'
import { translatePath } from '../translations'

const PageSucessCampaign = () => {
  const { t } = useLanguage()

  return (
    <>
      <Head 
        pathname={translatePath('/sucesso-campanha/')} 
        title="Dimas Construções" 
        description="Obrigada pela indicação. Em breve, te atualizaremos de como foi o nosso contato."
      />
      <section className={st.core}>
        <div>
          <h1>{t('PageSucessCampaign.title')}</h1>
          <p>
            {t('PageSucessCampaign.paragraph.1')}
            <br />
            {t('PageSucessCampaign.paragraph.2')}
          </p>
          <button onClick={() => navigate("/")} className="btn">
            {t('PageSucessCampaign.button')}
          </button>
        </div>
      </section>
    </>
  )
}

export default PageSucessCampaign
